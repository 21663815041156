import React, { useState, useEffect } from "react";
import { Table, Typography, Modal, Button, Tooltip } from "antd";
import { Currency } from "../../utils/Utils";
import DateTimeFormat from "../../components/DateTimeFormat/DateTimeFormat";

const Labs = () => {
  const [userData, setUserData] = useState(null);
  const [viewData, setViewData] = useState(null);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const userDataString = sessionStorage.getItem('userData');
    if (userDataString) {
      const userDataObject = JSON.parse(userDataString);
      if (userDataObject.ordered_labs) {
        userDataObject.ordered_labs.sort((a, b) => new Date(b.date_added) - new Date(a.date_added));
      }
      setUserData(userDataObject);
    }
  }, []);

  const columns = [
    {
      title: "#",
      render: (_, record, index) => index + 1,
      align: "center",
      width: "5%",
    },
    {
      title: "Lab Order ID",
      dataIndex: "lab_id", 
      key: "lab_id",
      width: "15%",
    },

    {
      title: "Ordered Labs",
      dataIndex: "lab_name",
      key: "lab_name",
      render: (labNames, record) => {
        if (labNames && labNames?.length > 0) {
          const truncatedNames = labNames?.slice(0, 3).map(lab => lab.lab_name);
          const displayNames = truncatedNames.join(", ");
          return labNames?.length > 3 ? displayNames + "..." : displayNames;
        } else {
          return "N/A";
        }
      },
    },
    {
      title: "Order Type",
      dataIndex: "lab_id",
      key: "lab_id",
      align:'center',
      width: "10%",
      render: (lab_id) =>
        lab_id.includes("LAB_EX") ? (
          <span className="bg-green-600 px-2 py-[2px] text-white text-xs rounded-md">
            External
          </span>
        ) : (
          <span className="bg-[#3484F0] px-2 py-[2px] text-white text-xs rounded-md">
            In-House
          </span>
        ),
    },
    {
      title: "Provider",
      dataIndex: "added_by",
      key: "added_by",
      align:"center",
      width: "15%",
      render: (text) => (
        <span title={text}>
          {text.length > 15 ? text.slice(0, 15) + "..." : text}
        </span>
      ),
    },
    {
      dataIndex: "date_added",
      key: "date_added",
      title: "Date",
      width:"10%",
      align:'center',
      sorter: (a, b) => {
        const dateA = new Date(a.date_added);
        const dateB = new Date(b.date_added);
        return dateA - dateB;
      },
      render: (text) => (
        <Tooltip title={<DateTimeFormat date={text} type="time" />}>
        <span>{text ? <DateTimeFormat  date={text} type="date" /> : ""}</span>
      </Tooltip>
      ),
    },
    {
      dataIndex: "action",
      key: "action",
      align:'center',
      width:'10%',
      title: "Action",
      render: (text, record) => (
        <Button type="primary" size="small" onClick={() => handleView(record)}>View Order</Button>
      ),
    },
  ];

  const handleView = (record) => {
    setViewData(record);
    setVisible(true);
  };

  return (
    <>
      <Typography.Title level={4} className="mb-3">
       Ordered Labs
      </Typography.Title>
      <Table
        size="small"
        columns={columns}
        dataSource={userData?.ordered_labs || []}
        rowKey={(record) => record?.lab_id}
        pagination={false}
      />

      <Modal open={visible} footer={null} onCancel={() => setVisible(false)}
        closeIcon={
          <Button size="small" className="app-close-icon" shape="round">Close</Button>
        }
      >
        <div>
          <p>
            <span className="font-medium">Added By: </span>
            {viewData?.added_by}
          </p>
          <p>
            <span className="font-medium">Date Added: </span>
            <DateTimeFormat date={viewData?.date_added} type="datetime"/>
          </p>
          <p>
            <span className="font-medium">Lab Type: </span>
            {viewData?.lab_id.includes("L_E") ? 'External' : 'In-House'}
          </p>
          <p>
            <span className="font-medium">Lab Order ID: </span>
            {viewData?.lab_id}
          </p>
          {viewData?.lab_name.map((lab, index) => (
            <div key={index} className="mt-3 bg-slate-100 p-3">
              <p>
                <span className="font-medium">Lab: </span>
                {lab.lab_name}
              </p>
              {lab.lab_instructions && (
                <p>
                  <span className="font-medium">Instructions: </span>
                  {lab.lab_instructions}
                </p>
              )}
              {lab.lab_diagnosis && (
                <p>
                  <span className="font-medium">Diagnosis: </span>
                  {lab.lab_diagnosis}
                </p>
              )}
              {lab.lab_price && (
                <p>
                  <span className="font-medium">Price: </span>
                  <Currency value={lab.lab_price}/>
                </p>
              )}
            </div>
          ))}
        </div>
      </Modal>
    </>
  );
};

export default Labs;
